/* eslint-disable class-methods-use-this, import/no-extraneous-dependencies */
import useJwt from '@/auth/jwt/useJwt'

export default class CommerciaService {
  static list(filters, page = 0) {
    let params = {}
    if (filters !== undefined) {
      params = Object.fromEntries(Object.entries(filters).filter(filter => filter[1] !== '' && filter[1] !== 'all'))
    }

    params.order_by = params.sortBy + (params.sortDesc ? ' DESC' : '')
    delete params.sortDesc
    delete params.sortBy

    return useJwt.get(`/commercia/users/list?page=${page}`, params)
  }

  static getUser(id) {
    return useJwt.get(`/commercia/user/${id}`)
  }
}
